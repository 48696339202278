import React from 'react';
import { Link, NavLink } from 'react-router-dom';
class CptHeader extends React.Component {
    render() {
        return (
            <div className="header-area">
                <div
                    style={{ padding: '0', margin: '0', paddingLeft: '20px', paddingRight: '20px' }}
                    id="sticky-header"
                    className="main-header-area">
                    <div className="container-fluid p-0">
                        <div className="row align-items-center no-gutters">
                            <div className="col-xl-2 col-lg-2">
                                <div className="logo-img">
                                    <a href="index.html">
                                        <img src="https://imgur.com/WaJdvcz.png" referrerPolicy="no-referrer" alt="Logo The LOOP" style={{ width: '112px', height: '92px' }} />
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8">
                                <div id="chinhsua" className="main-menu d-none d-lg-block">
                                    <nav>
                                        <ul id="navigation">
                                            <li><NavLink to="/" className="active">Trang chủ</NavLink></li>
                                            <li><NavLink to="/rooms">Phòng &amp; lều</NavLink></li>
                                            <li><NavLink to="/tiec">Tổ chức tiệc</NavLink></li>
                                            <li>
                                                <NavLink to="/restaurant">Nhà hàng &amp; Café<i className="ti-angle-down"></i></NavLink>
                                                <ul className="submenu">
                                                    <li><a href="theloop_res_drinks.html">Thức uống</a></li>
                                                    <li><a href="theloop_res_bbq.html">BBQ</a></li>
                                                    <li><a href="theloop_res_disum.html">Disum</a></li>
                                                    <li><a href="theloop_res_bistro.html">Bistro</a></li>
                                                    <li><a href="theloop_res_combo.html">Combo</a></li>
                                                </ul>
                                            </li>
                                            <li><NavLink to="/blogs">Blogs<i className="ti-angle-down"></i></NavLink>
                                                <ul className="submenu">
                                                    <li><Link to="/blogs">Blogs</Link></li>
                                                    <li><Link to="/blogs">Đà Lạt</Link></li>
                                                    <li><Link to="/blogs">Câu chuyện</Link></li>
                                                </ul>
                                            </li>
                                            <li><NavLink to="/sales">Khuyến mãi</NavLink></li>
                                            <li><NavLink to="/service">Dịch vụ khác</NavLink></li>
                                            <li><NavLink to="/about">Về chúng tôi</NavLink></li>
                                            <li><NavLink to="/contact">Liên hệ</NavLink></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 d-none d-lg-block">
                                <div className="book_room">
                                    <div className="socail_links" style={{ padding: '0', margin: '0' }}>
                                        <ul>
                                            <li>
                                                <a href="https://www.facebook.com/theLOOPboutique">
                                                    <i className="fa fa-facebook-square" style={{ color: '#f8f8f8' }}></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.tiktok.com/@theloopboutique">
                                                    <i className="fab fa-tiktok" style={{ color: '#f8f8f8' }}></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/theloopboutiquehotelandcafe/">
                                                    <i className="fa fa-instagram" style={{ color: '#f8f8f8' }}></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="book_btn d-none d-lg-block" style={{ width: '90%' }}>
                                        <a href="https://m.me/101023215038087" target="_blank" rel="noopener noreferrer">Đặt phòng</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mobile_menu d-block d-lg-none"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CptHeader;
