import React from 'react';
import "../App.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Sales extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg_13">
                    <h3>Khuyến mãi</h3>
                </div>

                <div className="about_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5">
                                <div className="about_info">
                                    <div className="section_title mb-20px">
                                        <span>Khuyến mãi</span>
                                        <h3>The LOOP Boutique <br />
                                            Hotel & Café</h3>
                                    </div>
                                    <p>The LOOP xin giới thiệu chương trình khuyến mãi đặc biệt,
                                        mang lại cho khách hàng cơ hội tận hưởng kỳ nghỉ đáng nhớ với mức giá không thể tốt hơn.
                                        Đừng bỏ lỡ cơ hội lý tưởng để thư giãn và khám phá,
                                        với nhiều gói dịch vụ được giảm giá đặc biệt cho mùa này.

                                    </p>
                                    <a href="index.html" className="line-button">Đọc thêm</a>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7">
                                <div className="about_thumb d-flex">
                                    <div className="img_1">
                                        <img src="https://imgur.com/0FYfc9V.png" referrerPolicy="no-referrer" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div className="about_info_area">
                    <OwlCarousel className='owl-theme' loop margin={10} nav items={1} autoplay={true} dots={true}>
                        <div className='item'>
                            <div className="single_slider about_bg_16"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_17"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_18"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_19"></div>
                        </div>
                    </OwlCarousel>
                </div>





                <div className="about_main_info">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-6">
                                <div className="single_about_info">
                                    <h3>Chương trình ưu đãi tri ân Khách hàng</h3>
                                    <p>
                                        Khám phá kỳ nghỉ đáng nhớ tại The LOOP với chương trình khuyến mãi hấp dẫn! Hãy tận hưởng mức giá ưu đãi đặc biệt
                                        trên các gói lưu trú, bao gồm cả trải nghiệm khác mà chúng tôi đang cung cấp.<br />
                                        Đặt trước ngay để đảm bảo bạn nhận được ưu đãi tốt nhất và trải nghiệm dịch vụ chu đáo, tận tình tại một trong
                                        những địa điểm nghỉ dưỡng lý tưởng nhất. <br />
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="single_about_info">
                                    <h3>Cập nhật thông tin khuyến mãi NGAY</h3>
                                    <p> Cập nhật thông tin khuyến mãi từ The LOOP từ các kênh
                                        truyền thông chính của chúng tôi, như: Facebook, Instagram và TikTok.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="forQuery">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1 col-md-12">
                                <div className="Query_border">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="Query_text">
                                                <p>Liên hệ ngay

                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="phone_num">
                                                <a href="https://m.me/101023215038087" class="mobile_no">0825 -176- 929</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="instragram_area">
                    <div className="single_instagram">
                        <img src="https://imgur.com/il7RNfo.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/C26z48uruDa/?img_index=3">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/8YgXUJi.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/CtnkUfwv53R/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/exNcVbN.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/C3xJzYOoRhF/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/9bH9d6e.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/Cpt6mLDv6V6/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/uYaRLju.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/CxZG-5xsTlY/?img_index=1">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default Sales;
