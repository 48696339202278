import React from 'react';
import "../App.css";

class BlogsHoa extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg_3">
                    <h3>Blogs</h3>
                </div>
                <div className="blog-container">
                    <h1>3 điểm ngắm mai anh đào ở Đà Lạt đầu năm</h1>

                    <div className="image-section">
                        <img src="https://imgur.com/Mq41eQg.png" alt="hoadao_blog1" />
                        <p className="source">Nguồn: The LOOP Boutique Hotel & Café</p>
                        <div className="description">
                            <p>
                                Hoa đào Đà Lạt, biểu tượng của mùa xuân nơi cao nguyên lãng mạn, không chỉ đẹp đẽ qua từng bông hoa
                                mà còn là hình ảnh gợi lên sự tinh tế, mong manh và đầy sức sống. Mỗi dịp xuân về, hoa đào nở rộ
                                khắp các ngõ ngách của Đà Lạt, từ những khu vườn yên tĩnh đến những con đường quanh co, mang theo
                                hương vị của núi rừng, tạo nên một khung cảnh đẹp đến nao lòng.
                            </p>
                        </div>
                    </div>

                    <div className="image-section">
                        <img src="https://imgur.com/0aqn9Is.png" alt="hoadao_blog2" />
                        <p className="source">Nguồn: Sưu tầm</p>
                        <div className="description">
                            <p>
                                Địa điểm: Mộng Đào Nguyên<br />
                                Mộng Đào Nguyên Đà Lạt, một địa điểm nổi tiếng với vẻ đẹp nên thơ và mơ mộng, là nơi lưu giữ những
                                hàng đào rực rỡ nhất mỗi dịp xuân về. Trong không gian yên bình và lãng mạn của Mộng Đào Nguyên, hoa
                                đào nở rộ, khoe sắc thắm giữa bầu trời xanh và mây trắng lãng đãng, tạo nên một bức tranh thiên nhiên
                                huyền ảo và đầy màu sắc.
                            </p>
                        </div>
                    </div>

                    <div className="image-section">
                        <img src="https://imgur.com/ZRnZa4w.png" alt="hoadao_blog3" />
                        <p className="source">Nguồn: Sưu tầm</p>
                        <div className="description">
                            <p>
                                Địa điểm: Khu vực cầu đất<br />
                                Cầu Đất không chỉ thu hút du khách bởi vẻ đẹp của hoa đào, mà còn bởi không khí trong lành, yên bình
                                và cảnh quan thiên nhiên hùng vĩ. Dạo bước trên những con đường mòn, qua những hàng cây cổ thụ, ngắm
                                nhìn vẻ đẹp của hoa đào nở rộ là một trong những trải nghiệm không thể quên khi đến thăm Đà Lạt.
                            </p>
                        </div>
                    </div>

                    <div className="image-section">
                        <img src="https://imgur.com/p61OA90.png" alt="hoadao_blog4" />
                        <p className="source">Nguồn: Sưu tầm</p>
                        <div className="description">
                            <p>
                                Địa điểm: Rừng Hoa Đào - Lạc Dương.<br />
                                Rừng Hoa Đào tại Lạc Dương, một khu vực thuộc tỉnh Lâm Đồng, nổi tiếng là thiên đường của những bông hoa
                                đào nở rộ, mang đến một khung cảnh nên thơ và huyền ảo cho du khách khi mùa xuân về. Nằm cách thành
                                phố Đà Lạt không xa, Rừng Hoa Đào Lạc Dương là điểm đến lý tưởng cho những ai muốn tìm kiếm sự yên bình,
                                tách biệt khỏi nhịp sống hối hả và đắm chìm vào vẻ đẹp thuần khiết của thiên nhiên.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogsHoa;
