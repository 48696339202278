import React from 'react';
import "../App.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Tiec extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg_7">
                    <h3>Tổ chức tiệc</h3>
                </div>

                <div className="about_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5">
                                <div className="about_info">
                                    <div className="section_title mb-20px">
                                        <span>Tổ chức tiệc</span>
                                        <h3>The LOOP Boutique <br />
                                            Hotel & Café</h3>
                                    </div>
                                    <p>The LOOP cung cấp một không gian lý tưởng cho các buổi tiệc gia đình, tiệc kỷ niệm, hoặc chỉ đơn giản là một buổi tối bên bạn bè,
                                        không gian xung là khung cảnh đẹp đẽ và yên bình của Đà Lạt.
                                        Đặt bàn ngay hôm nay và để The LOOP biến mỗi khoảnh khắc của bạn thành trải nghiệm không thể quên.</p>
                                    <a href="index.html" className="line-button">Đọc thêm</a>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7">
                                <div className="about_thumb d-flex">
                                    <div className="img_1">
                                        <img src="https://imgur.com/GyWPAQg.png" referrerPolicy="no-referrer" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div className="about_info_area">
                    <OwlCarousel className='owl-theme' loop margin={10} nav items={1} autoplay={true} dots={true}>
                        <div className='item'>
                            <div className="single_slider about_bg_8"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_9"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_10"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_11"></div>
                        </div>
                    </OwlCarousel>
                </div>





                <div className="about_main_info">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-6">
                                <div className="single_about_info">
                                    <h3>Chuẩn bị bàn tiệc theo ý tưởng của bạn</h3>
                                    <p>
                                        Tại The LOOP, mỗi bàn tiệc được sắp xếp và trang trí tỉ mỉ để phù hợp với không gian và chủ đề của buổi tiệc.
                                        Chúng tôi chăm chút từng chi tiết nhỏ, từ việc lựa chọn khăn trải bàn phù hợp cho đến bố trí bình hoa tươi tạo điểm nhấn,
                                        mang đến một bữa tiệc không chỉ ngon miệng mà còn đẹp mắt. <br />
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="single_about_info">
                                    <h3>Nguyên Liệu và Sự Đầu Tư trong Tổ Chức Tiệc Tối</h3>
                                    <p> Chất lượng của một bữa tiệc không chỉ đến từ sự set up mà còn từ chính nguyên liệu được sử dụng. Tại The LOOP,
                                        chúng tôi đặt mức độ ưu tiên cao cho việc lựa chọn nguyên liệu tươi ngon và chất lượng cao,
                                        đảm bảo đa dạng về các món ăn. <br />The LOOP không chỉ là
                                        nơi thưởng thức ẩm thực mà còn là nơi để khách hàng tạo dựng những ký ức khó quên cùng người thân và bạn bè.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="forQuery">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1 col-md-12">
                                <div className="Query_border">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="Query_text">
                                                <p>Liên hệ ngay

                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="phone_num">
                                                <a href="https://m.me/101023215038087" class="mobile_no">0825 -176- 929</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="instragram_area">
                    <div className="single_instagram">
                        <img src="https://imgur.com/il7RNfo.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/C26z48uruDa/?img_index=3">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/8YgXUJi.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/CtnkUfwv53R/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/exNcVbN.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/C3xJzYOoRhF/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/9bH9d6e.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/Cpt6mLDv6V6/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/uYaRLju.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/CxZG-5xsTlY/?img_index=1">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default Tiec;
