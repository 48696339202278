import React from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css";

class Home extends React.Component {
    render() {



        return (
            <div>
                <div style={{ marginBottom: 0, paddingBottom: '10px', paddingTop: '0px' }}>
                    <div className="video_area_inner text-center">
                        <iframe
                            style={{ width: '100%', height: '900px', padding: 0, margin: 0 }}
                            src="https://www.youtube.com/embed/catHsUc6V_4?autoplay=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>


                <div className="about_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5">
                                <div className="about_info">
                                    <div className="section_title mb-20px">
                                        <span>Về chúng tôi</span>
                                        <h3>The LOOP Boutique <br />
                                            Hotel & Café</h3>
                                    </div>
                                    <p>Hoạt động từ năm 2020, The LOOP Boutique Hotel & Café là khu tổ hợp độc đáo, kết hợp giữa lưu
                                        trú và quán café, cách trung tâm thành phố Đà Lạt 3,5km.
                                        Thiết kế chủ đạo mang tông màu trắng, kiến trúc của LOOP nổi bật với hình dáng khối tròn độc
                                        đáo....</p>
                                    <Link to="/">Đọc thêm</Link>
                                </div>
                            </div>


                            <div className="col-xl-7 col-lg-7">
                                <div className="about_thumb d-flex">
                                    <div className="img_1">
                                        <img src="https://imgur.com/NgHcvQe.png" alt="Ảnh giới thiệu The LOOP 1" />
                                    </div>
                                    <div class="img_2">
                                        <img src="https://imgur.com/1wQFNx0.png" alt="Ảnh giới thiệu The LOOP" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="offers_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section_title text-center mb-100">
                                    <span>The LOOP</span>
                                    <h3>Ưu đãi trong tháng </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-md-4">
                                <div className="single_offers">
                                    <div className="about_thumb">
                                        <img src="https://imgur.com/wkCCRtl.png" referrerpolicy="no-referrer" alt="" />
                                    </div>
                                    <h3>The LOOP: Đặc biệt khi chọn tổ chức tiệc LOOP
                                        <br />
                                    </h3>
                                    <ul>
                                        <li>Không gian lý tưởng</li>
                                        <li>Thực đơn theo yêu cầu</li>
                                        <li>Dịch vụ tổ chức chuyên nghiệp</li>
                                    </ul>
                                    <a href="https://m.me/101023215038087" class="book_now">Đặt ngay</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4">
                                <div className="single_offers">
                                    <div className="about_thumb">
                                        <img src="https://imgur.com/Ny0JTxz.png" referrerpolicy="no-referrer" alt="" />
                                    </div>
                                    <h3>The LOOP: Khám phá sự đặc biệt tại phòng V thơ mộng</h3>
                                    <ul>
                                        <li>Phòng cho 4 người lớn và 1 trẻ dưới 6 tuổi</li>
                                        <li>Hướng sân vườn, view đồi</li>
                                        <li>Đầy đủ các tiện nghi</li>
                                    </ul>
                                    <a href="https://m.me/101023215038087" className="book_now">Đặt ngay</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4">
                                <div className="single_offers">
                                    <div className="about_thumb">
                                        <img src="https://imgur.com/4adB9tk.png" referrerpolicy="no-referrer" alt="" />
                                    </div>
                                    <h3>The LOOP: Menu đồ uống mới bạn nên thử
                                        <br />
                                    </h3>
                                    <ul>
                                        <li>Menu nước mới </li>
                                        <li>Giảm giá ngày lễ</li>
                                        <li>Ưu đãi đặt bàn trước</li>
                                    </ul>
                                    <a href="https://m.me/101023215038087" class="book_now">Đặt ngay</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                <div className="about_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7">
                                <div className="about_thumb2 d-flex">
                                    <div className="img_1">
                                        <img src="https://imgur.com/RvfIHDv.png" referrerpolicy="no-referrer" alt="Food_loop" />
                                    </div>
                                    <div class="img_2">
                                        <img src="https://imgur.com/7Rmqq5z.png" referrerpolicy="no-referrer" alt="Food_loop" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5">
                                <div className="about_info">
                                    <div className="section_title mb-20px">
                                        <span>Dịch vụ</span>
                                        <h3>Chất lượng <br />
                                            tương xứng với<br /> trải nghiệm</h3>
                                    </div>
                                    <p>Thực đơn The LOOP không chỉ đa dạng với các món nước mà còn đặc sắc với hương vị đặc trưng
                                        của từng món ăn. Đặc biệt, trải nghiệm thưởng thức BBQ tại đây còn là cơ hội trải nghiệm
                                        những hương vị mới: chất lượng và đa dạng.
                                    </p>
                                    <a href="index.html" class="line-button">Đọc thêm</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="features_room">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section_title text-center mb-100">
                                    <span>Phòng &amp; Lều </span>
                                    <h3>Lựa chọn tốt nhất</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rooms_here">
                        <div className="single_rooms">
                            <div className="room_thumb">
                                <img src="https://imgur.com/VM6MqPT.png" referrerpolicy="no-referrer" alt="room_loop" />
                                <div className="room_heading d-flex justify-content-between align-items-center">
                                    <div className="room_heading_inner">
                                        <span></span>
                                        <h3>Phòng I</h3>
                                    </div>
                                    <a href="https://m.me/101023215038087" class="line-button">Đặt ngay</a>
                                </div>
                            </div>
                        </div>
                        <div className="single_rooms">
                            <div className="room_thumb">
                                <img src="https://imgur.com/ERZ5dGD.png" referrerpolicy="no-referrer" alt="pic_loop_tent" />
                                <div className="room_heading d-flex justify-content-between align-items-center">
                                    <div className="room_heading_inner">
                                        <span></span>
                                        <h3>Phòng V</h3>
                                    </div>
                                    <a href="https://m.me/101023215038087" class="line-button">Đặt ngay</a>
                                </div>
                            </div>
                        </div>
                        <div className="single_rooms">
                            <div className="room_thumb">
                                <img src="https://imgur.com/RZl1aKh.png" referrerpolicy="no-referrer" alt="" />
                                <div className="room_heading d-flex justify-content-between align-items-center">
                                    <div className="room_heading_inner">
                                        <span></span>
                                        <h3>Phòng IV</h3>
                                    </div>
                                    <a href="https://m.me/101023215038087" class="line-button">Đặt ngay</a>
                                </div>
                            </div>
                        </div>
                        <div className="single_rooms">
                            <div className="room_thumb">
                                <img src="https://imgur.com/I9qtVMO.png.png" referrerpolicy="no-referrer" alt="" />
                                <div className="room_heading d-flex justify-content-between align-items-center">
                                    <div className="room_heading_inner">
                                        <span></span>
                                        <h3>Phòng III</h3>
                                    </div>
                                    <a href="https://m.me/101023215038087" className="line-button">Đặt ngay</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="forQuery">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1 col-md-12">
                                <div className="Query_border">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="Query_text">
                                                <p>Liên hệ ngay</p>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-6">
                                            <div class="phone_num">
                                                <a href="https://m.me/101023215038087" className="mobile_no">0825 -176- 929</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="instragram_area">
                    <div className="single_instagram">
                        <img src="https://imgur.com/il7RNfo.png" referrerpolicy="no-referrer" alt="" />
                        <div class="ovrelay">
                            <a href="https://www.instagram.com/p/C26z48uruDa/?img_index=3">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/8YgXUJi.png" referrerpolicy="no-referrer" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/CtnkUfwv53R/?img_index=1">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/exNcVbN.png" referrerpolicy="no-referrer" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/C3xJzYOoRhF/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/9bH9d6e.png" referrerpolicy="no-referrer" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/C00NkMgvCCS/?img_index=4">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/uYaRLju.png" referrerpolicy="no-referrer" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/CxZG-5xsTlY/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Home;