import React from 'react';
import { NavLink } from 'react-router-dom';

class CptFooter extends React.Component {
    render() {
        const currentYear = new Date().getFullYear();
        return (
            <div className="footer">
                <div className="footer_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-xl-12 col-md-6 col-lg-12">
                                        <div className="footer_widget">
                                            <h3 className="footer_title">
                                                Địa chỉ: Đường An Sơn, Phường 4, Đà Lạt
                                            </h3>
                                            <iframe
                                                title="Địa chỉ: Đường An Sơn, Phường 4, Đà Lạt"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1001367.2332658484!2d106.92120106898395!3d11.364573338295102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31716de5fec4715b%3A0xe74747ab1ecd6714!2sThe%20LOOP%20Boutique%20Hotel%20%26%20Cafe!5e0!3m2!1svi!2s!4v1713369745439!5m2!1svi!2s"
                                                width="500"
                                                height="250"
                                                style={{ border: 0 }}
                                                allowFullScreen
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-xl-6 col-md-6 col-lg-6">
                                        <div className="footer_widget">
                                            <h3 className="footer_title">
                                                The LOOP
                                            </h3>
                                            <nav>
                                                <ul id="navigation">
                                                    <li><NavLink to="/">Trang chủ</NavLink></li>
                                                    <li><NavLink to="/rooms">Phòng & lều</NavLink></li>
                                                    <li><NavLink to="/events">Tổ chức tiệc</NavLink></li>
                                                    <li><NavLink to="/restaurant">Nhà hàng &amp; Café</NavLink></li>
                                                    <li><NavLink to="/blogs">Blogs</NavLink></li>
                                                    <li><NavLink to="/promotions">Khuyến mãi</NavLink></li>
                                                    <li><NavLink to="/services">Dịch vụ khác</NavLink></li>
                                                    <li><NavLink to="/about">Về chúng tôi</NavLink></li>
                                                    <li><NavLink to="/contact">Liên hệ</NavLink></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-6 col-lg-6">
                                        <div className="footer_widget">
                                            <h3 className="footer_title">
                                                NHẬN TIN ƯU ĐÃI
                                            </h3>
                                            <form action="#" className="newsletter_form">
                                                <input type="text" placeholder="Nhập địa chỉ email" />
                                                <button type="submit">Gửi</button>
                                            </form>
                                            <p className="newsletter_text">Theo dõi và nhận tin ưu đãi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-right_text">
                    <div className="container">
                        <div className="footer_border"></div>
                        <div className="row">
                            <div className="col-xl-8 col-md-7 col-lg-9">
                                <p className="copy_right">
                                    {currentYear} | Mang đến cho bạn những trải nghiệm thú vị <i className="fa fa-heart-o" aria-hidden="true"></i> by <a href="index.html" target="_blank">LOOP</a>
                                </p>
                            </div>
                            <div className="col-xl-4 col-md-5 col-lg-3">
                                <div class="socail_links">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/theLOOPboutique">
                                                <i class="fa fa-facebook-square"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.tiktok.com/@theloopboutique">
                                                <i class="fab fa-tiktok"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/theloopboutiquehotelandcafe/">
                                                <i class="fa fa-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CptFooter;
