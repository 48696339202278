import React from 'react';
import "../App.css";

class BlogsPic extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg_3">
                    <h3>Blogs</h3>
                </div>
                <div className="blog-container">
                    <h1>Những góc check-in siêu xịn tại LOOP</h1>

                    <div className="image-section">
                        <img src="https://imgur.com/ZH71iiJ.png" alt="trachieu_blog1" />
                        <p className="source">Nguồn: The LOOP Boutique Hotel & Café</p>
                        <div className="description">
                            <p>
                                Sự kết hợp giữa kiến trúc hiện đại và yếu tố thiên nhiên tạo nên một background ấn tượng. Góc chụp
                                nổi bật với không gian rộng lớn, được bao phủ bởi màu trắng của hoa lau đung đưa
                                theo gió, tạo nên một khung cảnh đầy thơ mộng và lãng mạn.
                            </p>
                        </div>
                    </div>

                    <div className="image-section">
                        <img src="https://imgur.com/wclOLjI.png" alt="bistro_blog2" />
                        <p className="source">Góc tủ sách The LOOP</p>
                        <div className="description">
                            <p>
                                Góc tủ sách của The LOOP- không gian ấm cúng và yên bình. Một khung cảnh lý tưởng cho
                                những ai muốn tìm một góc chụp độc đáo và đẹp mắt. Đây không chỉ là nơi lưu giữ tri thức mà còn là
                                điểm nhấn trong không gian của The LOOP, thu hút bất kỳ ai yêu sách và sự tĩnh
                                lặng.
                            </p>
                        </div>
                    </div>

                    <div className="image-section">
                        <img src="https://imgur.com/UMfmtEa.png" alt="trachieu_blog3" />
                        <p className="source">View nhìn ra nhà lồng tại LOOP</p>
                        <div className="description">
                            <p>
                                The LOOP không chỉ đem đến cho bạn những món nước ngon, đồ ăn chất lượng mà bạn hoàn toàn có thể
                                thưởng thức những điều này với một góc view ưng ý khi đến LOOP. Không chỉ là một góc chụp ảnh lý
                                tưởng, view này còn là nơi bạn có thể tận hưởng không gian yên bình, thư giãn.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogsPic;
