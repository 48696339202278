import React from 'react';
import { Link, NavLink } from "react-router-dom";

class Blogs extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg_3">
                    <h3>Blogs</h3>
                </div>


                <section className="blog_area section-padding" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mb-5 mb-lg-0">
                            <div className="blog_left_sidebar">

                                <article className="blog_item">
                                    <div className="blog_item_img">
                                        <img className="card-img rounded-0" src="https://imgur.com/2g23zw3.png" alt="" />
                                        <Link to="/" className="blog_item_date">
                                            <h3>9</h3>
                                            <p>Jul</p>
                                        </Link>
                                    </div>

                                    <div className="blog_details">
                                        <NavLink to="/BlogsTrienLam" className="d-inline-block">
                                            <h2>Triển lãm tranh lụa "LƠ TƠ MƠ" tại the LOOP</h2>
                                        </NavLink>

                                        <p>Được biết đến như một nơi có kiến trúc "lạ mắt" và đặc biệt ở Đà Lạt, the LOOP luôn
                                            muốn chia sẻ tới không chỉ khách hàng của mình về câu chuyện kiến trúc trong không
                                            gian sống, mà còn là nơi dành cho bất kì ai đang muốn gửi gắm những tác phẩm nghệ
                                            thuật của mình nhằm truyền tải nghệ thuật tới công chúng cũng như lan toả những
                                            thông điệp đẹp qua nghệ thuật.</p>
                                        <ul className="blog-info-link">
                                            <li><NavLink to="/categories/travel-lifestyle"><i className="fa fa-user"></i> Travel, Lifestyle</NavLink></li>
                                            <li><NavLink to="/comments"><i className="fa fa-comments"></i> 03 Comments</NavLink></li>
                                        </ul>
                                    </div>
                                </article>

                                <article className="blog_item">
                                    <div className="blog_item_img">
                                        <img className="card-img rounded-0" src="https://imgur.com/9odwLHa.png" alt="" />
                                        <Link to="/" className="blog_item_date">
                                            <h3>15</h3>
                                            <p>Jan</p>
                                        </Link>
                                    </div>

                                    <div className="blog_details">

                                        <NavLink to="/BlogsBistro" className="d-inline-block">
                                            <h2>Món mới siêu ngon, BISTRO The LOOP</h2>
                                        </NavLink>

                                        <p>Mỗi món ăn tại The Loop Bistro được tạo ra với sự kỳ vọng mang đến trải nghiệm ẩm
                                            thực Pháp đích thực, nơi hương vị truyền thống kết hợp hài hòa với những phương pháp
                                            chế biến hiện đại.</p>
                                        <ul className="blog-info-link">
                                            <li><NavLink to="/categories/travel-lifestyle"><i className="fa fa-user"></i> FoodFOOD, Lifestyle</NavLink></li>
                                            <li><NavLink to="/comments"><i className="fa fa-comments"></i> 20 Comments</NavLink></li>
                                        </ul>
                                    </div>
                                </article>

                                <article className="blog_item">
                                    <div className="blog_item_img">
                                        <img className="card-img rounded-0" src="https://imgur.com/85zWGWk.png" alt="" />
                                        <Link to="/" className="blog_item_date">
                                            <h3>15</h3>
                                            <p>Jan</p>
                                        </Link>
                                    </div>

                                    <div className="blog_details">
                                        <NavLink to="/BlogsTra" className="d-inline-block">
                                            <h2>Thưởng trà chiều mưa ở LOOP</h2>
                                        </NavLink>

                                        <p>Những buổi chiều mưa Đà Lạt, buổi trà chiều mưa luôn mang lại một trải nghiệm thư
                                            giãn độc đáo,
                                            nơi bạn có thể tận hưởng khoảnh khắc yên bình, lắng đọng bên ly trà ấm áp. Ngắm nhìn
                                            những giọt mưa
                                            rơi bên ngoài cửa kính. <br />Không gian này được thiết kế với ý tưởng vừa hiện đại
                                            vừa ấm
                                            cúng, không hề
                                            cũ kỹ, tạo nên một không gian lý tưởng để thư giãn và tận hưởng những giây phút bình
                                            yên.</p>
                                        <ul className="blog-info-link">
                                            <li><NavLink to="/categories/travel-lifestyle"><i className="fa fa-user"></i>DaLat, Lifestyle</NavLink></li>
                                            <li><NavLink to="/comments"><i className="fa fa-comments"></i> 03 Comments</NavLink></li>
                                        </ul>
                                    </div>
                                </article>

                                <article className="blog_item">
                                    <div className="blog_item_img">
                                        <img className="card-img rounded-0" src="https://imgur.com/X5nlzbI.png" alt="" />
                                        <Link to="/" className="blog_item_date">
                                            <h3>2</h3>
                                            <p>Feb</p>
                                        </Link>
                                    </div>

                                    <div className="blog_details">
                                        <NavLink to="/BlogsHoa" className="d-inline-block">
                                            <h2>  3 điểm ngắm mai anh đào ở Đà Lạt đầu năm</h2>
                                        </NavLink>
                                        <p>Điểm ngay 3 địa điểm ngắm hoa anh đào cực đẹp ở Đà Lạt tháng này. Hiện nay, nhiều đồi
                                            hoa đã sắp sửa
                                            khoe sắc, thời gian dự kiến là khoảng 1 tuần nữa, hoa sẽ nở rất rộ</p>
                                        <ul className="blog-info-link">
                                            <li><NavLink to="/categories/travel-lifestyle"><i className="fa fa-user"></i>DaLat, Lifestyle</NavLink></li>
                                            <li><NavLink to="/comments"><i className="fa fa-comments"></i> 11 Comments</NavLink></li>
                                        </ul>
                                    </div>
                                </article>

                                <article className="blog_item">
                                    <div className="blog_item_img">
                                        <img className="card-img rounded-0" src="https://imgur.com/3HHb4rO.png" alt="" />
                                        <Link to="/" className="blog_item_date">
                                            <h3>20</h3>
                                            <p>Dec</p>
                                        </Link>
                                    </div>

                                    <div className="blog_details">
                                        <NavLink to="/BlogsPic" className="d-inline-block">
                                            <h2>Những góc check-in siêu xịn tại LOOP</h2>
                                        </NavLink>

                                        <p>The LOOP, với thiết kế độc đáo và không gian mở, mỗi góc nhà LOOP luôn trở nên đặc
                                            biệt và giúp bạn có những góc check-in đẹp. Dưới
                                            đây là một số góc check-in không thể bỏ qua khi bạn ghé thăm The LOOP</p>
                                        <ul className="blog-info-link">
                                            <li><NavLink to="/categories/travel-lifestyle"><i className="fa fa-user"></i>Drinks, Lifestyle</NavLink></li>
                                            <li><NavLink to="/comments"><i className="fa fa-comments"></i> 05 Comments</NavLink></li>
                                        </ul>
                                    </div>
                                </article>

                                <nav className="blog-pagination justify-content-center d-flex">
                                    <ul className="pagination">
                                        <li className="page-item">
                                            <Link to="#" className="page-link" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                                <span className="sr-only">Previous</span>
                                            </Link>
                                            <i className="ti-angle-left"></i>
                                        </li>
                                        <li className="page-item">
                                            <Link to="/desired-path" className="page-link">
                                                1
                                            </Link>
                                        </li>
                                        <Link to="/desired-path" className="page-link">
                                            2
                                        </Link>
                                        <li className="page-item">
                                            <Link to="/next-page" className="page-link" aria-label="Next">
                                                <i className="ti-angle-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="blog_right_sidebar">
                                <aside className="single_sidebar_widget search_widget">
                                    <form action="#">
                                        <div className="form-group">
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder='Nhập từ khóa'
                                                    onfocus="this.placeholder = ''"
                                                    onblur="this.placeholder = 'Tìm kiếm'" />
                                                <div className="input-group-append">
                                                    <button className="btn" type="button" /><i className="ti-search" />
                                                </div>
                                            </div>
                                        </div>
                                        <button className="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
                                            type="submit">Tìm kiếm</button>
                                    </form>
                                </aside>

                                <aside className="single_sidebar_widget post_category_widget">
                                    <h4 className="widget_title">Phân loại </h4>
                                    <ul className="list cat-list">
                                        <li>
                                            <Link to="/travel-news" className="d-flex">
                                                <p>Du lịch </p>
                                                <p>(37)</p>
                                            </Link>

                                        </li>
                                        <li>
                                            <Link to="/travel-news" className="d-flex">
                                                <p>Nhà hàng</p>
                                                <p>(10)</p>
                                            </Link>

                                        </li>
                                        <li>
                                            <Link to="/drink" className="d-flex">
                                                <p>Đồ uống</p>
                                                <p>(3)</p>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/room" className="d-flex">
                                                <p>Phòng</p>
                                                <p>(3)</p>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/travel-news" className="d-flex">
                                                <p>Stories</p>
                                                <p>(3)</p>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/travel-news" className="d-flex">
                                                <p>DaLat</p>
                                                <p>(9)</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </aside>

                                <aside className="single_sidebar_widget popular_post_widget">
                                    <h3 className="widget_title">Bài đăng gần đây</h3>
                                    <div className="media post_item">
                                        <img src="https://imgur.com/HPkEKvU.png" alt="post" />
                                        <div className="media-body">
                                            <Link to="/single-blog">
                                                <h3>The LOOP - BBQ</h3>
                                            </Link>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="media post_item">
                                        <img src="https://imgur.com/eTlXAQ3.png" alt="post" />
                                        <div className="media-body">
                                            <Link to="/single-blog">
                                                <h3>Khoảnh khắc The LOOP</h3>
                                            </Link>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="media post_item">
                                        <img src="https://imgur.com/qBaPKwX.png" alt="post" />
                                        <div className="media-body">
                                            <Link to="/single-blog">
                                                <h3>Đặt bàn trước</h3>
                                            </Link>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className="media post_item">
                                        <img src="https://imgur.com/vQ6zyAq.png" alt="post" />
                                        <div className="media-body">
                                            <Link to="/single-blog">
                                                <h3>Bữa sáng đậm đà hương vị với một ly cà phê nóngc</h3>
                                            </Link>
                                            <p></p>
                                        </div>
                                    </div>
                                </aside>
                                <aside className="single_sidebar_widget tag_cloud_widget">
                                    <h4 className="widget_title">Tags</h4>
                                    <ul className="list">
                                        <li>
                                            <Link to="/loop-tent">LOOP Tent</Link>
                                        </li>
                                        <li>
                                            <Link to="/loop-bbq">BBQ</Link>
                                        </li>
                                        <li>
                                            <Link to="/loop-cf">Cà phê</Link>
                                        </li>
                                        <li>
                                            <Link to="/loop-bbq">Rooms</Link>
                                        </li>
                                        <li>
                                            <Link to="/loop-res">Nhà hàng </Link>
                                        </li>
                                        <li>
                                            <Link to="/loop-dimsum">Dimsum</Link>
                                        </li>
                                        <li>
                                            <Link to="/loop-tent">Tent</Link>
                                        </li>
                                        <li>
                                            <Link to="/loop-voucher">Khuyen mai</Link>
                                        </li>
                                    </ul>
                                </aside>


                                <aside className="single_sidebar_widget instagram_feeds">
                                    <h4 className="widget_title">Instagram Feeds</h4>
                                    <ul className="instagram_row flex-wrap">
                                        <li>
                                            <a href="https://www.instagram.com/p/C4RZIvEp1-g/?img_index=1">
                                                <img className="img-fluid" src="https://imgur.com/yvfGpPJ.png" alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/p/C5NnpXErH0h/?img_index=2">
                                                <img className="img-fluid" src="https://imgur.com/4UUTqKN.png" alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/p/C3bpmbfLg3K/?img_index=1">
                                                <img className="img-fluid" src="https://imgur.com/jfMrQp1.png" alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/p/C2xEw4ps6HD/?img_index=1">
                                                <img className="img-fluid" src="https://imgur.com/lxmMGEl.png" alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/p/Cwz7f3Dvx7B/?img_index=1">
                                                <img className="img-fluid" src="https://imgur.com/g00RBMv.png" alt="" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/p/CwT42ygP5rS/?img_index=1">
                                                <img className="img-fluid" src="https://imgur.com/nsjCFM9.png" alt="" />
                                            </a>
                                        </li>
                                    </ul>
                                </aside>


                                <aside className="single_sidebar_widget newsletter_widget">
                                    <h4 className="widget_title">Bản tin</h4>

                                    <form action="#">
                                        <div className="form-group">
                                            <input type="email" className="form-control" onfocus="this.placeholder = ''"
                                                onblur="this.placeholder = 'Enter email'" placeholder='Nhập mail' required />
                                        </div>
                                        <button className="button rounded-0 primary-bg text-white w-100 btn_1 boxed-btn"
                                            type="submit">Đăng ký</button>
                                    </form>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}
export default Blogs;
