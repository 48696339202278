import React from 'react';
import "../App.css";

class Contact extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg_5">
                    <h3>Liên hệ</h3>
                </div>

                <section className="contact-section" />
                <div className="container">
                    <div className="d-none d-sm-block mb-5 pb-4">
                        <h3> The LOOP- Liên hệ</h3>
                        <p>Chúng tôi luôn sẵn lòng lắng nghe từ bạn! Nếu bạn có bất kỳ câu hỏi, đề xuất, hoặc chỉ đơn giản muốn
                            chia sẻ trải nghiệm của mình, đừng ngần ngại liên hệ với chúng tôi. Mỗi ý kiến từ bạn là nguồn cảm
                            hứng vô giá giúp chúng tôi ngày càng hoàn thiện hơn.

                        </p>
                    </div>


                    <div className="row">
                        <div className="col-12">
                            <h2 className="contact-title">Liên lạc:</h2>
                        </div>
                        <div className="col-lg-8">
                            <form className="form-contact contact_form" action="contact_process.php" method="post" id="contactForm"
                                novalidate="novalidate">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea class="form-control w-100" name="message" id="message" cols="30" rows="9"
                                                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Message'"
                                                placeholder=" Nội dung gửi"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input class="form-control valid" name="name" id="name" type="text"
                                                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'"
                                                placeholder="Nhập họ và tên" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input class="form-control valid" name="email" id="email" type="email"
                                                onfocus="this.placeholder = ''"
                                                onblur="this.placeholder = 'Nhập địa chỉ email'" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input class="form-control" name="subject" id="subject" type="text"
                                                onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter Subject'"
                                                placeholder="Nhập chủ đề gửi" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <button type="submit" className="button button-contactForm boxed-btn">Gửi</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-3 offset-lg-1">
                            <div className="media contact-info">
                                <span className="contact-info__icon"><i className="ti-home"></i></span>
                                <div className="media-body">
                                    <h3>Địa chỉ</h3>
                                    <p>Đường An Sơn, Phường 4, Đà Lạt</p>
                                </div>
                            </div>
                            <div className="media contact-info">
                                <span className="contact-info__icon"><i className="ti-tablet"></i></span>
                                <div className="media-body">
                                    <h3>Hotline</h3>
                                    <p>0825 -176- 929</p>
                                </div>
                            </div>
                            <div className="media contact-info">
                                <span className="contact-info__icon"><i className="ti-email"></i></span>
                                <div className="media-body">
                                    <h3>Mail</h3>
                                    <p>marketing.theloop@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Contact;
