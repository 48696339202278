import React from 'react';
import "../App.css";
class BlogsTrienLam extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg_3">
                    <h3>Blogs</h3>
                </div>

                <div className="blog-container">
                    <h1>Triển lãm tranh lụa "LƠ TƠ MƠ" tại the LOOP</h1>

                    <div className="image-section">
                        <img src="https://imgur.com/cpe5UgR.png" referrerPolicy="no-referrer" alt="Lơ Tơ Mơ_blog1" />
                        <p className="source">Nguồn: The LOOP Boutique Hotel & Café</p>

                        <div className="description">
                            <p>Triển lãm tranh lụa "LƠ TƠ MƠ" tại the LOOP</p>
                            <p>Được biết đến như một nơi có kiến trúc "lạ mắt" và đặc biệt ở Đà Lạt, the LOOP luôn
                                muốn chia sẻ tới không chỉ khách hàng của mình về câu chuyện kiến trúc trong không
                                gian sống, mà còn là nơi dành cho bất kì ai đang muốn gửi gắm những tác phẩm nghệ
                                thuật của mình nhằm truyền tải nghệ thuật tới công chúng cũng như lan toả những
                                thông điệp đẹp qua nghệ thuật.</p>
                        </div>
                    </div>

                    <div className="image-section">
                        <img src="https://imgur.com/0uq9HH9.png" referrerPolicy="no-referrer" alt="Lơ Tơ Mơ_blog2" />
                        <p className="source">Nhiều khách đến LOOP và tham gia triễn lãm</p>

                        <div className="description">
                            <p>Mỗi tác phẩm tranh lụa tại đây đều kể một câu chuyện, một góc nhìn, một cảm xúc riêng biệt, qua đó mở
                                ra một không gian đa chiều về cảm quan và trí tuệ. Đến với "Lơ Tơ Mơ", bạn không chỉ được thưởng lãm
                                nghệ thuật, mà còn được đắm chìm vào không gian văn hóa, tinh thần, và cảm xúc, nơi mà từng nét vẽ,
                                từng sắc màu trên lụa đều thấm đượm tình yêu và niềm đam mê với vẻ đẹp truyền thống.</p>
                        </div>
                    </div>

                    <div className="image-section">
                        <img src="https://imgur.com/x4huuUs.png" referrerPolicy="no-referrer" alt="Lơ Tơ Mơ_blog3" />
                        <p className="source">Hình ảnh một số tác phẩm tại "Lơ Tơ Mơ"</p>

                        <div className="description">
                            <p>The LOOP tự hào là địa điểm tổ chức, nơi đã chuẩn bị một không gian lý tưởng để mỗi tác phẩm có thể
                                toả sáng và được trân trọng một cách xứng đáng. Hãy đến và cảm nhận triển lãm "Lơ Tơ Mơ", nơi bạn sẽ
                                tìm thấy sự yên bình, nguồn cảm hứng và sự kết nối sâu sắc với văn hóa Việt thông qua nghệ thuật
                                tranh lụa độc đáo.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogsTrienLam;
