import React from 'react';
import "../App.css";
import { Link } from 'react-router-dom';
class Restaurant extends React.Component {
    render() {
        return (
            <div>

                <div className="bradcam_area breadcam_bg_4">
                    <h3> Nhà hàng và Café</h3>
                </div>

                <section className="sample-text-area" ></section>
                <div className="container box_1170">
                    <h3 className="text-heading">The LOOP - Nhà hàng và Café</h3>
                    <p className="sample-text">
                        Trong không gian của The LOOP luôn mang đến một trải
                        nghiệm ẩm thực đặc sắc và đa dạng, phục vụ mọi nhu cầu và sở thích của thực khách.
                        The LOOP mang đến một thực đơn phong phú với các món ăn và đồ uống từ khắp nơi trên thế giới, từ Á đến
                        Âu, từ truyền thống đến hiện đại. Mỗi món ăn được chăm chút tỉ mỉ, từ việc lựa chọn nguyên liệu tươi
                        ngon nhất đến quy trình chế biến đặc trưng, tạo ra những hương vị độc đáo và khó quên.<br /><br />
                        Nhà hàng The LOOP trở thành điểm hẹn lý
                        tưởng cho những buổi tụ họp bạn bè, gia đình hoặc những khoảnh khắc đặc biệt.
                        <br /><br />
                        Một trong những ưu tiên hàng đầu của đội ngũ The LOOP là đem lại trải nghiệm ẩm thực tốt nhất cho thực
                        khách,
                        từ việc tư vấn menu, giải đáp thắc mắc đến việc chăm sóc khách hàng một cách tận tình và chu
                        đáo.<br /><br />
                        Sự chu đáo và tận tâm của đội ngũ nhân viên thể hiện rõ ràng qua việc lựa chọn nguyên liệu. Nhà hàng cam
                        kết sử dụng những nguyên liệu tươi mới nhất, được chọn lọc kỹ càng từ các nhà cung cấp uy tín và nổi
                        tiếng với chất lượng sản phẩm hàng đầu. Điều này đảm bảo rằng mỗi món ăn không chỉ ngon miệng mà còn bổ
                        dưỡng và an toàn cho sức khỏe của thực khách.<br /><br />
                        Tại The LOOP, thực khách sẽ được trải nghiệm đa dạng các dịch vụ ẩm thực, bao gồm:<br />
                        <br />
                        <strong>
                            1. Drinks. <br />
                            2. BBQ. <br />
                            3. Dimsum. <br />
                            4. Bistro. <br />
                            5. Combo. <br />
                        </strong>
                    </p>
                </div>

                <section className="section bg-image overlay" style={{ backgroundImage: "url('https://imgur.com/SLa7lzI.png')" }}>
                    {
                        <div className="container">
                            <div className="row justify-content-center text-center mb-5">
                                <div className="col-md-7">
                                    <h2 className="heading text-white" data-aos="fade">Menu</h2>
                                </div>
                            </div>
                            <div className="food-menu-tabs" data-aos="fade">
                                <ul className="nav nav-tabs mb-5" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active letter-spacing-2" id="drinks-tab" data-toggle="tab" href="#drinks"
                                            role="tab" aria-controls="drinks" aria-selected="true">Drinks</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link letter-spacing-2" id="BBQ-tab" data-toggle="tab" href="#BBQ" role="tab"
                                            aria-controls="BBQ" aria-selected="false">BBQ</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link letter-spacing-2" id="dimsum-tab" data-toggle="tab" href="#dimsum" role="tab"
                                            aria-controls="dimsum" aria-selected="false">Dimsum</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link letter-spacing-2" id="bistro-tab" data-toggle="tab" href="#bistro" role="tab"
                                            aria-controls="bistro" aria-selected="false">Bistro</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link letter-spacing-2" id="combo-tab" data-toggle="tab" href="#combo" role="tab"
                                            aria-controls="combo" aria-selected="false">Combo</a>
                                    </li>
                                </ul>
                                <div className="tab-content py-5" id="myTabContent">

                                    <div className="tab-pane fade show active text-left" id="drinks" role="tabpanel"
                                        aria-labelledby="drinks-tab">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3">Coffee</span>
                                                    <h3 className="text-white">
                                                        <Link to="/vietnamese-coffee" className="text-white">Vietnamese Coffee</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Cà phê đen/sữa, Bạc xỉu.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/italian-coffee" className="text-white">Vietnamese Coffee</Link></h3>
                                                    <p className="text-white text-opacity-7">Espresso, Americano, Cappuccino, Latte, Mocha.
                                                    </p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/non-coffee" className="text-white">Non - coffee</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Matcha latte, Matcha Macchiato, Choco latte,
                                                        Choco Macchiato.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/cold-brew" className="text-white">Cold Brew</Link>

                                                    </h3>
                                                    <p className="text-white text-opacity-7">Macchiato, Dâu tằm, Dâu tây, Thơm, Chanh dây.
                                                    </p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3">Tea</span>
                                                    <h3 className="text-white">

                                                        <Link to="/tra-trai-cay" className="text-white">Trà trái cây tươi</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7"> Lipton thảo mộc, Dâu tằm, Chanh dây cam, Dâu
                                                        tây.</p>
                                                </div>
                                                <div className="food-menu mb-6">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/tra-nong" className="text-white">Trà nóng</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Fruit tea detox, Lotus tea, Quí phi trà, Bạch
                                                        cúc phổ nhĩ, Hồng phổ nhĩ, Hoa hồng đường nâu.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">

                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3">Fruit Juice</span>
                                                    <h3 className="text-white">
                                                        <Link to="/nuoc-ep" className="text-white">Nước ép</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Dưa hấu, Thơm, Cam, Táo, Dâu.<br /></p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/da-xay" className="text-white">Đá xay</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Chocolate chuối, Dâu Đà Lạt, Trà xanh, Xoài,
                                                        Bơ.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/sua-chua" className="text-white">Sữa chua trái cây</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Phô-mai, Xoài, Chanh dây, Trái cây, Bơ, Dâu tây.
                                                    </p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3">Beer</span>
                                                    <h3 className="text-white">
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Vang chille, LOOP craft beer, Hoegaarden rosée,
                                                        Hoegaarden white, Budweiser aluminum, Chimay blue, Leffe nâu, Corona, Blanc
                                                        1664, <br />Super stout mini.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="tab-pane fade text-left" id="BBQ" role="tabpanel" aria-labelledby="BBQ-tab">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/set-1" className="text-white">Set tiêu chuẩn 1</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Bò cuộn nấm kim châm, Mực nướng sate, Tôm nướng
                                                        sate, Ba chỉ heo nướng mọi, Kim chi, Nấm đông cô nướng, Xiên que rau củ,
                                                        Salad oliu, <br />Xà lách.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/set-2" className="text-white">Set tiêu chuẩn 2</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Cá tầm nướng, Mực nướng sate, Sườn heo nướng,
                                                        Kim chi, Nấm đông cô nướng, Xiên que rau củ, Salad oliu, Xà lách.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                    </h3>
                                                    <p className="text-white text-opacity-7"></p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/set-dac-biet" className="text-white"> Set đặc biệt  </Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Cá tầm nướng/ Cá hồi nướng, Tôm nướng sate,
                                                        Thăn bò, Kim chi, Nấm đông cô nướng, Xiên que rau củ, Salad oliu, Xà lách. </p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                    </h3>
                                                    <p className="text-white text-opacity-7"></p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                    </h3>
                                                    <p class="text-white text-opacity-7"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade text-left" id="dimsum" role="tabpanel" aria-labelledby="dimsum-tab">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/dim-sum" className="text-white">Dimsum</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Sủi cảo chiên, Bánh hẹ chiên
                                                        &amp; Xíu mại nhân thịt</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/ha-cao" className="text-white">Há cảo</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Há cảo tôm, sò điệp, mắt phụng, tôm hẹ </p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/dau-hu" className="text-white">Đậu hũ ky</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7"></p>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/banh-cuon" className="text-white">Bánh cuốn Hong Kong </Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7"></p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/banh-cuon" className="text-white">Bánh bao </Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Trứng muối, Xá xíu, Kim sa, Phô mai, Mè đen
                                                        và
                                                        Bánh bao 3 vị.</p>
                                                </div>
                                                <div class="food-menu mb-5">
                                                    <span class="d-block text-primary h4 mb-3"></span>
                                                    <h3 class="text-white">
                                                        <Link to="/chan-ga" className="text-white">Chân gà hấp tàu xì</Link>
                                                    </h3>
                                                    <p class="text-white text-opacity-7"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade text-left" id="bistro" role="tabpanel" aria-labelledby="bistro-tab">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3">Khai vị</span>
                                                    <h3 className="text-white">
                                                        <Link to="/rau-cu" className="text-white">Rau củ Đà Lạt nướng</Link>

                                                    </h3>
                                                    <p className="text-white text-opacity-7">Măng tây, Cà rốt baby, Ớt chuông, Cà chua
                                                        baby,
                                                        Nấm đông cô.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/salad" className="text-white">Salad Oliug</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Cải kale, Cải mầm.</p>
                                                </div>
                                                <div clasNames="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/nam-hap" className="text-white">Nấm hấp tẩm vị &amp; Rau
                                                            củ nướng</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Nấm hấp, Măng tây, Cà chua baby.</p><br />
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/nam-mix" className="text-white">Nấm mix nướng</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Nấm đông cô, Nấm đùi gà, Nấm linh chi.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/dau-hu-ky" className="text-white">Đậu hũ ky áp chảo</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">5 cuốn, salad oliu.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/khoai-tay" className="text-white">Khoai tây chiên</Link></h3>
                                                    <p className="text-white text-opacity-7">Khoai tây sợi.</p>
                                                </div>



                                            </div>

                                            <div className="col-md-6">
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3">Món chính</span>
                                                    <h3 className="text-white">
                                                        <Link to="/bo-luc-lac" className="text-white">Bò lúc lắc khoai tây</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Bò Úc siêu mềm, Khoai tây, Ớt chuông, Hành
                                                        tây.
                                                    </p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/ca-hoi" className="text-white">Cá hồi áp chảo</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Cá hồi, Măng tây.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/cha-ca" className="text-white">Chả cá thu chiên</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Chả cá, Salad oliu.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/luon-ngong" className="text-white">Lườn ngỗng áp chảo</Link>

                                                    </h3>
                                                    <p className="text-white text-opacity-7">Lườn ngỗng, Kim chi.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/ba-roi-xong-khoi" className="text-white">Ba rọi xông khói cuộn măng
                                                            tây</Link>

                                                    </h3>
                                                    <p className="text-white text-opacity-7">Ba rọi xông khói, Măng tây.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/bo-cuon" className="text-white">Bò cuộn</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Bò cuộn phô mai, Bò cuộn nấm kim châm, Kim
                                                        chi.
                                                    </p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/bo-cuon" className="text-white">Cánh gà nướng</Link>

                                                    </h3>
                                                    <p className="text-white text-opacity-7">Cánh gà, Kim chi.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/bo-bit-tet" className="text-white">Bò bít tết</Link>

                                                    </h3>
                                                    <p className="text-white text-opacity-7">Bò Úc siêu mềm, Salad oliu.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/my-y" className="text-white">Mỳ ý bò phô mai</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Mì barilla, Bò sốt phô mai.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/ca-tam-nuong" className="text-white">Cá tầm nướng</Link>

                                                    </h3>
                                                    <p className="text-white text-opacity-7">Cá tầm, Măng tây, Cà chua măng tây.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade text-left" id="combo" role="tabpanel" aria-labelledby="combo-tab">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/combo-1" className="text-white">Combo 1</Link>

                                                    </h3>
                                                    <p className="text-white text-opacity-7">Bò Úc siêu mềm 400g, Salad Oliu.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/combo-2" className="text-white">Combo 2</Link>
                                                    </h3>
                                                    <p className="text-white text-opacity-7">Bò Úc siêu mềm 200g, Xúc xích mix 300g,
                                                        Khoai
                                                        tây chiên 150g,<br /> Salad Oliu.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/combo-3" className="text-white">Combo 3</Link></h3>
                                                    <p className="text-white text-opacity-7">Bò Úc siêu mềm 200g, Mỳ ý bò phô mai, Salad
                                                        Oliu.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/combo-4" className="text-white">Combo 4</Link></h3>
                                                    <p className="text-white text-opacity-7">Bò Úc siêu mềm 200g, Salad Oliu.</p>
                                                </div>
                                                <div className="food-menu mb-5">
                                                    <span className="d-block text-primary h4 mb-3"></span>
                                                    <h3 className="text-white">
                                                        <Link to="/combo-mix" className="text-white">Combo Sausage Mix</Link></h3>
                                                    <p className="text-white text-opacity-7">Xúc xích mix 300g, Khoai tây chiên 200g,
                                                        Salad
                                                        Oliu.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    }
                </section>


            </div >

        );
    }
}
export default Restaurant;