import React from 'react';
class Rooms extends React.Component {
    render() {
        return (
            <div>

                <div class="bradcam_area breadcam_bg_1">
                    <h3>Phòng và Lều</h3>
                </div>

                <div className="offers_area padding_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section_title text-center mb-100">
                                    <span>The LOOP</span>
                                    <h3>Phòng và Lều</h3>
                                    <h4>LOOP - nơi không gian mở chen nhiều khoảng xanh thoáng đãng, hiện đại và tràn ngập ánh sáng
                                        tự nhiên. Với 11 phòng và 2 khu lều trại, mỗi không gian đều được chăm sóc kỹ lưỡng, đảm bảo
                                        trang bị đầy đủ tiện nghi. Không chỉ tập trung đầu tư vào không gian ấn tượng, LOOP còn
                                        hướng đến trải nghiệm thoải mái và gần gũi cho mỗi vị khách lưu trú tại đây với nhiều hoạt
                                        động thú vị khác.
                                    </h4>
                                </div>
                            </div>
                        </div>




                        <div className="row">
                            <div className="row">
                                <div className="col-xl-4 col-md-4">
                                    <div className="single_offers">
                                        <div className="about_thumb">
                                            <img src="https://imgur.com/Sv9JUsL.png" referrerpolicy="no-referrer" alt="" />
                                        </div>

                                        <h3>Phòng VIII</h3>
                                        <ul >
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                                                2 người lớn &amp; 1 trẻ dưới 6 tuổi.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0h32c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64H64c123.7 0 224 100.3 224 224v32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V320C100.3 320 0 219.7 0 96z" /></svg>
                                                Hướng sân vườn, view thung lũng.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 32C0 14.3 14.3 0 32 0H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H112zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H368zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8H328z" /></svg>
                                                Tiện nghi:  Free wifi, Toilet riêng, Máy sấy tóc, Ấm siêu tốc, Bàn chải đánh răng tre, Kem đánh răng P/S, Lược gỗ,
                                                Bông tẩy trang, Quạt, Tinh dầu, Bánh ngọt,...
                                            </p>
                                        </ul>
                                        <a href="https://m.me/101023215038087" class="book_now">Đặt ngay</a>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4">
                                    <div className="single_offers">
                                        <div className="about_thumb">
                                            <img src="https://imgur.com/eq9oMyw.png" referrerpolicy="no-referrer" alt="" />
                                        </div>
                                        <h3>Phòng VI</h3>
                                        <ul>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                                                2 người lớn &amp; 1 trẻ dưới 6 tuổi.
                                            </p>

                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0h32c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64H64c123.7 0 224 100.3 224 224v32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V320C100.3 320 0 219.7 0 96z" /></svg>
                                                Hướng sân vườn.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 32C0 14.3 14.3 0 32 0H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H112zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H368zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8H328z" /></svg>
                                                Tiện nghi:  Free wifi, Toilet riêng, Máy sấy tóc, Ấm siêu tốc,
                                                Bàn chải đánh răng tre, Kem đánh răng P/S, Lược gỗ,
                                                Bông tẩy trang, Quạt, Tinh dầu, Bánh ngọt,...                                          </p>
                                        </ul>
                                        <a href="https://m.me/101023215038087" className="book_now">Đặt ngay</a>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4">
                                    <div className="single_offers">
                                        <div className="about_thumb">
                                            <img src="https://imgur.com/tJMa2e0.png" referrerpolicy="no-referrer" alt="" />
                                        </div>
                                        <h3> Phòng II</h3>
                                        <ul>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                                                4 người lớn &amp; 1 trẻ dưới 6 tuổi.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M560 160A80 80 0 1 0 560 0a80 80 0 1 0 0 160zM55.9 512H381.1h75H578.9c33.8 0 61.1-27.4 61.1-61.1c0-11.2-3.1-22.2-8.9-31.8l-132-216.3C495 196.1 487.8 192 480 192s-15 4.1-19.1 10.7l-48.2 79L286.8 81c-6.6-10.6-18.3-17-30.8-17s-24.1 6.4-30.8 17L8.6 426.4C3 435.3 0 445.6 0 456.1C0 487 25 512 55.9 512z" /></svg>
                                                Hướng sân vườn, view đồi.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 32C0 14.3 14.3 0 32 0H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H112zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H368zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8H328z" /></svg>
                                                Tiện nghi: Ban công, Free wifi, Toilet riêng, Máy sấy tóc, Ấm siêu tốc,
                                                Bàn chải đánh răng tre, Kem đánh răng P/S, Lược gỗ,
                                                Bông tẩy trang, Quạt, Tinh dầu, Bánh ngọt,...
                                            </p>
                                        </ul>
                                        <a href="https://m.me/101023215038087" className="book_now">Đặt ngay</a>
                                    </div>
                                </div>
                            </div>




                            <div style={{ paddingTop: 30 + 'px' }} className="row">
                                <div className="col-xl-4 col-md-4">
                                    <div className="single_offers">
                                        <div className="about_thumb">
                                            <img src="https://imgur.com/9ax8UOb.png" referrerpolicy="no-referrer" alt="" />
                                        </div>
                                        <h3>Phòng XII</h3>
                                        <ul>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                                                2 người lớn &amp; 1 trẻ  dưới 6 tuổi.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0h32c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64H64c123.7 0 224 100.3 224 224v32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V320C100.3 320 0 219.7 0 96z" /></svg>
                                                Hướng sân vườn, view thung lũng.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 32C0 14.3 14.3 0 32 0H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H112zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H368zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8H328z" /></svg>
                                                Tiện nghi: Bồn tắm gỗ, Free wifi, Toilet riêng, Máy sấy tóc, Ấm siêu tốc,
                                                Bàn chải đánh răng tre, Kem đánh răng P/S, Lược gỗ,
                                                Bông tẩy trang, Quạt, Tinh dầu, Bánh ngọt,...
                                            </p>
                                        </ul>
                                        <a href="https://m.me/101023215038087" className="book_now">Đặt ngay</a>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4">
                                    <div className="single_offers">
                                        <div className="about_thumb">
                                            <img src="https://imgur.com/LE9m3RM.png" referrerpolicy="no-referrer" alt="" />
                                        </div>
                                        <h3>Phòng IX</h3>
                                        <ul>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                                                2 người lớn &amp; 1 trẻ dưới 6 tuổi.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0h32c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64H64c123.7 0 224 100.3 224 224v32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V320C100.3 320 0 219.7 0 96z" /></svg>
                                                Hướng sân vườn, view thung lũng.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 32C0 14.3 14.3 0 32 0H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H112zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H368zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8H328z" /></svg>
                                                Tiện nghi: Ban công, Sofa bed, Bồn tắm gỗ, Free wifi, Toilet riêng, Máy sấy tóc, Ấm siêu tốc,
                                                Bàn chải đánh răng tre, Kem đánh răng P/S, Lược gỗ,
                                                Bông tẩy trang, Quạt, Tinh dầu, Bánh,...
                                            </p>
                                        </ul>
                                        <a href="https://m.me/101023215038087" className="book_now">Đặt ngay</a>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-md-4">
                                    <div className="single_offers">
                                        <div className="about_thumb">
                                            <img src="https://imgur.com/jpi4fU1.png" referrerpolicy="no-referrer" alt="" />
                                        </div>
                                        <h3>Phòng VII</h3>
                                        <ul>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" /></svg>
                                                3 người lớn &amp; 1 trẻ dưới 6 tuổi.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0h32c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64H64c123.7 0 224 100.3 224 224v32V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V320C100.3 320 0 219.7 0 96z" /></svg>
                                                Hướng sân vườn.
                                            </p>
                                            <p>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 32C0 14.3 14.3 0 32 0H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H112zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H368zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8H328z" /></svg>
                                                Tiện nghi: Ban công, Free wifi, Toilet riêng, Máy sấy tóc, Ấm siêu tốc,
                                                Bàn chải đánh răng tre, Kem đánh răng P/S, Lược gỗ,
                                                Bông tẩy trang, Quạt, Tinh dầu, Bánh ngọt,...                                            </p>
                                        </ul>
                                        <a href="https://m.me/101023215038087" className="book_now">Đặt ngay</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="features_room">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="section_title text-center mb-100">
                                        <span> Phòng &amp; Lều </span>
                                        <h3>Lựa chọn tốt nhất</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rooms_here">
                            <div className="single_rooms">
                                <div className="room_thumb">
                                    <img src="https://imgur.com/GAfjTku.png" alt="tent1" />
                                    <div className="room_heading d-flex justify-content-between align-items-center">
                                        <div className="room_heading_inner">
                                            <span>LOOPTENT</span>
                                            <h3>LOOP Tent 1</h3>
                                        </div>
                                        <a href="https://m.me/101023215038087" className="book_now">Đặt ngay</a>
                                    </div>
                                </div>
                            </div>
                            <div className="single_rooms">
                                <div className="room_thumb">
                                    <img src="https://imgur.com/Ff6kmCm.png" alt="tent1" />
                                    <div className="room_heading d-flex justify-content-between align-items-center">
                                        <div className="room_heading_inner">
                                            <span>LOOPTENT</span>
                                            <h3>LOOP Tent 1</h3>
                                        </div>
                                        <a href="https://m.me/101023215038087" className="book_now">Đặt ngay</a>
                                    </div>
                                </div>
                            </div>
                            <div className="single_rooms">
                                <div className="room_thumb">
                                    <img src="https://imgur.com/D8QjUYO.png" alt="tent2" />
                                    <div className="room_heading d-flex justify-content-between align-items-center">
                                        <div className="room_heading_inner">
                                            <span>LOOPTENT</span>
                                            <h3>LOOP Tent 2</h3>
                                        </div>
                                        <a href="https://m.me/101023215038087" class="book_now">Đặt ngay</a>
                                    </div>
                                </div>
                            </div>
                            <div className="single_rooms">
                                <div className="room_thumb">
                                    <img src="https://imgur.com/rI8oxa1.png" alt="tent2 2" />
                                    <div className="room_heading d-flex justify-content-between align-items-center">
                                        <div className="room_heading_inner">
                                            <span>LOOPTENT</span>
                                            <h3>LOOP Tent 2</h3>
                                        </div>
                                        <a href="https://m.me/101023215038087" class="book_now">Đặt ngay</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="forQuery">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-10 offset-xl-1 col-md-12">
                                    <div className="Query_border">
                                        <div className="row align-items-center justify-content-center">
                                            <div className="col-xl-6 col-md-6">
                                                <div className="Query_text">
                                                    <p>Liên hệ ngay</p>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-md-6">
                                                <div class="phone_num">
                                                    <a href="https://m.me/101023215038087" className="mobile_no">0825 -176- 929</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="instragram_area">
                        <div className="single_instagram">
                            <img src="https://imgur.com/il7RNfo.png" referrerpolicy="no-referrer" alt="" />
                            <div class="ovrelay">
                                <a href="https://www.instagram.com/p/C26z48uruDa/?img_index=3">
                                    <i class="fa fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                        <div className="single_instagram">
                            <img src="https://imgur.com/8YgXUJi.png" referrerpolicy="no-referrer" alt="" />
                            <div className="ovrelay">
                                <a href="https://www.instagram.com/p/CtnkUfwv53R/?img_index=1">
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                        <div className="single_instagram">
                            <img src="https://imgur.com/exNcVbN.png" referrerpolicy="no-referrer" alt="" />
                            <div className="ovrelay">
                                <a href="https://www.instagram.com/p/C3xJzYOoRhF/?img_index=1">
                                    <i class="fa fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                        <div className="single_instagram">
                            <img src="https://imgur.com/9bH9d6e.png" referrerpolicy="no-referrer" alt="" />
                            <div className="ovrelay">
                                <a href="https://www.instagram.com/p/C00NkMgvCCS/?img_index=4">
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                        <div className="single_instagram">
                            <img src="https://imgur.com/uYaRLju.png" referrerpolicy="no-referrer" alt="" />
                            <div className="ovrelay">
                                <a href="https://www.instagram.com/p/CxZG-5xsTlY/?img_index=1">
                                    <i class="fa fa-instagram"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
export default Rooms;


