import React from 'react';

import Home from './pages/TrangChu';
import Rooms from './pages/Rooms';
import Tiec from './pages/Tiec';

import About from './pages/About';

import Blogs from './pages/Blogs';
import BlogsTrienLam from './pages/BlogsTrienLam';
import BlogsTra from './pages/BlogsTra';
import BlogsBistro from './pages/BlogsBistro';
import BlogsHoa from './pages/BlogsHoa';
import BlogsPic from './pages/BlogsPic';


import Restaurant from './pages/Restaurant';
import Sales from './pages/sales';
import Service from './pages/service';
import Contact from './pages/Contact';

import CptHeader from "./component/cpt_header";
import CptFooter from "./component/cpt_footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

function App() {
  return (
    <div>
      <Router>
        <CptHeader />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/tiec" element={<Tiec />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />


          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogstrienlam" element={<BlogsTrienLam />} />
          <Route path="/blogstra" element={<BlogsTra />} />
          <Route path="/blogsbistro" element={<BlogsBistro />} />
          <Route path="/blogshoa" element={<BlogsHoa />} />
          <Route path="/blogspic" element={<BlogsPic />} />




          <Route path="/restaurant" element={<Restaurant />} />

          <Route path="/sales" element={<Sales />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>



        <CptFooter />




      </Router>
    </div>
  );
}

export default App;
