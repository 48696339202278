import React from 'react';
import "../App.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class About extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg">
                    <h3>The LOOP</h3>
                </div>

                <div className="about_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5">
                                <div className="about_info">
                                    <div className="section_title mb-20px">
                                        <span>Về chúng tôi</span>
                                        <h3>The LOOP Boutique <br />
                                            Hotel & Café</h3>
                                    </div>
                                    <p>Thời gian ở bên <b>The LOOP</b> giống như bước vào một không gian độc đáo, nơi mỗi trải
                                        nghiệm
                                        được thiết kế để mang lại cảm giác thân thuộc, gần gũi. Đặc biệt hơn với lối kiến trúc đặc trưng.</p>
                                    <a href="index.html" className="line-button">Đọc thêm</a>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7">
                                <div className="about_thumb d-flex">
                                    <div className="img_1">
                                        <img src="https://imgur.com/5NTqiwC.png" referrerPolicy="no-referrer" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div className="about_info_area">
                    <OwlCarousel className='owl-theme' loop margin={10} nav items={1} autoplay={true} dots={true}>
                        <div className='item'>
                            <div className="single_slider about_bg_1"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_2"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_3"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_4"></div>
                        </div>
                    </OwlCarousel>
                </div>





                <div className="about_main_info">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-6">
                                <div className="single_about_info">
                                    <h3>Mang đến cho bạn <br />những trải nghiệm đáng nhớ</h3>
                                    <p>
                                        Từ các khu vườn bí mật đến những không gian sáng tạo,
                                        mỗi góc của LOOP đều là một lời mời gọi khám phá và kết nối. Mở ra một thế giới nơi sự
                                        tinh tế và đẳng cấp được trải nghiệm một cách nhẹ nhàng và tinh tế. <br />Hãy cùng chúng tôi
                                        bước
                                        vào một thế giới nơi sự kết nối và sáng tạo không bao giờ ngừng lại, khám phá những trải
                                        nghiệm mới và tạo nên những kỷ niệm sẽ còn đọng mãi trong tâm trí bạn.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="single_about_info">
                                    <h3>Dịch vụ The LOOP được chú trọng <br />vào chất lượng và đa dạng</h3>
                                    <p> The LOOP có nhiều dịch vụ đa dạng và hấp dẫn: lưu trú, bbq, dimsum,tổ chức tiệc,đốt lửa trại,...
                                        <br />Với đội ngũ nhân viên chuyên nghiệp, mỗi dịch vụ được chúng tôi chú trọng nhằm nâng cao trải nghiệm cho Quý khách hàng.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="forQuery">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1 col-md-12">
                                <div className="Query_border">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="Query_text">
                                                <p>Liên hệ ngay

                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="phone_num">
                                                <a href="https://m.me/101023215038087" class="mobile_no">0825 -176- 929</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="instragram_area">
                    <div className="single_instagram">
                        <img src="https://imgur.com/il7RNfo.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/C26z48uruDa/?img_index=3">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/8YgXUJi.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/CtnkUfwv53R/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/exNcVbN.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/C3xJzYOoRhF/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/9bH9d6e.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/Cpt6mLDv6V6/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/uYaRLju.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/CxZG-5xsTlY/?img_index=1">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default About;
