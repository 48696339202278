import React from 'react';
import "../App.css";

class BlogsTra extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg_3">
                    <h3>Blogs</h3>
                </div>
                <div className="blog-container">
                    <h1>Thưởng trà chiều mưa ở LOOP</h1>

                    <div className="image-section">
                        <img src="https://imgur.com/xju9OUP.png" alt="trachieu_blog1" />
                        <p className="source">Nguồn: The LOOP Boutique Hotel & Café</p>
                        <div className="description">
                            <p>
                                Những buổi chiều mưa Đà Lạt, buổi trà chiều mưa luôn mang lại một trải nghiệm thư giãn độc đáo,
                                nơi bạn có thể tận hưởng khoảnh khắc yên bình, lắng đọng bên ly trà ấm áp. Ngắm nhìn những giọt mưa
                                rơi bên ngoài cửa kính.<br /> Không gian này được thiết kế với ý tưởng vừa hiện đại vừa ấm cúng, không
                                hề
                                cũ kỹ, tạo nên một không gian lý tưởng để thư giãn và tận hưởng những giây phút bình yên.
                            </p>
                        </div>
                    </div>

                    <div className="image-section">
                        <img src="https://imgur.com/dLuw3g5.png" alt="bistro_blog2" />
                        <p className="source">Khung cảnh mỗi phòng cực "chill"</p>
                        <div className="description">
                            <p>
                                Menu trà chiều tại The LOOP được chăm chút tỉ mỉ, từ mỗi loại trà mà chúng tôi chọn đến các món ăn
                                nhẹ được
                                chuẩn bị chu đáo nhằm mang đến những trải nghiệm lưu trú phong phú và độc đáo dành riêng cho bạn. Từ
                                những chiếc
                                bánh ngọt đến thức uống, mỗi lựa chọn đều là sự kết hợp
                                của hương vị, nhấn mạnh sự tinh tế và chất lượng.
                            </p>
                        </div>
                    </div>

                    <div className="image-section">
                        <img src="https://imgur.com/f8Uilk4.png" alt="trachieu_blog3" />
                        <p className="source">Tách trà chiều tại phòng</p>
                        <div className="description">
                            <p>
                                Bước vào The LOOP, bạn sẽ ngay lập tức cảm nhận được không khí ấm cúng, được bao trùm bởi hương trà
                                thơm lừng kết hợp với mùi của bánh ngọt và thức ăn nhẹ vừa mới ra lò. Bạn có thể thưởng thức trà
                                chiều ở nhiều góc, ngoài ban công hoặc tại phòng nghỉ, mỗi nơi sẽ là một góc nhìn ra quang
                                xung quanh The LOOP và Đà Lạt mà chắc chắn bạn sẽ rất hài lòng.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogsTra;
