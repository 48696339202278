import React from 'react';
import "../App.css";

class BlogsBistro extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg_3">
                    <h3>Blogs</h3>
                </div>
                <div className="blog-container">
                    <h1>Món mới siêu ngon, BISTRO The LOOP</h1>
                    <div className="image-section">
                        <img src="https://imgur.com/dl5XYhL.png" alt="bistro_blog1" />
                        <p className="source">Nguồn: The LOOP Boutique Hotel & Café</p>
                        <div className="description">
                            <p>
                                Tọa lạc tại gần trung tâm thành phố Đà Lạt, The LOOP mang đến một bầu không khí lãng mạn, thư giãn
                                nhưng không kém phần tinh tế và sang trọng, điều này được chúng tôi đặt tâm tình qua từng chi tiết
                                trong thiết kế và mỗi món ăn được tạo ra.
                                <br />LOOP luôn là địa điểm lý tưởng cho buổi gặp mặt tiếp theo của bạn. Menu đa dạng với những món
                                ngon từ đủ loại nguyên liệu tươi ngon, mang đến trải nghiệm ẩm thực phong phú.
                            </p>
                        </div>
                    </div>
                    <div className="image-section">
                        <img src="https://imgur.com/uWUBS4k.png" alt="bistro_blog2" />
                        <p className="source">Những món ăn ngon tại The LOOP</p>
                        <div className="description">
                            <p>
                                Với menu đa dạng, mỗi món ăn tại Bistro The LOOP đều là sự kết hợp tuyệt vời giữa nguyên liệu tươi
                                ngon và sự sáng tạo trong cách chế biến. Điều này không chỉ làm nổi bật hương vị đặc trưng của từng nguyên liệu
                                mà còn mang đến những trải nghiệm ẩm thực khó quên cho thực khách.
                            </p>
                        </div>
                    </div>
                    <div className="image-section">
                        <img src="https://imgur.com/lWpBH6F.png" alt="bistro_blog3" />
                        <p className="source">Bistro tại The LOOP</p>
                        <div className="description">
                            <p>
                                Bistro The LOOP, với không gian ấm cúng và thiết kế tinh tế, đã nhanh chóng trở thành một trong những
                                địa chỉ yêu thích cho những ai đam mê ẩm thực và tìm kiếm một góc bình yên giữa lòng thành phố. <br />
                                Đội ngũ nhân viên tại Bistro The LOOP được đào tạo bài bản, luôn sẵn lòng phục vụ và chia sẻ với
                                khách hàng về câu chuyện đằng sau mỗi món ăn, giúp bữa ăn trở nên phong phú và đầy màu sắc hơn.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BlogsBistro;
