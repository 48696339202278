import React from 'react';
import "../App.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class Service extends React.Component {
    render() {
        return (
            <div>
                <div className="bradcam_area breadcam_bg_8">
                    <h3>Dịch vụ khác</h3>
                </div>

                <div className="about_area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-5">
                                <div className="about_info">
                                    <div className="section_title mb-20px">
                                        <span>Dịch vụ khác</span>
                                        <h3>The LOOP Boutique <br />
                                            Hotel & Café</h3>
                                    </div>
                                    <p>Tại LOOP, không chỉ các dịch vụ chính như lưu trú, café, tiệc nướng BBQ
                                        và Dimsum thu hút khách hàng hay tổ chức Workshop
                                        mà còn có nhiều hoạt động bổ sung khác nhằm mang đến trải nghiệm phong phú và đa dạng cho du khách.
                                        <br />Trong đó, có thể kể đến các dịch vụ nổi bật khác: trekking và thăm các nông trại Đà Lạt,
                                        mỗi hoạt động mang lại một cái nhìn sâu sắc và thú vị về vùng đất và văn hóa địa phương.

                                    </p>
                                    <a href="index.html" className="line-button">Đọc thêm</a>
                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7">
                                <div className="about_thumb d-flex">
                                    <div className="img_1">
                                        <img src="https://imgur.com/Ig3TU0Z.png" referrerPolicy="no-referrer" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div className="about_info_area">
                    <OwlCarousel className='owl-theme' loop margin={10} nav items={1} autoplay={true} dots={true}>
                        <div className='item'>
                            <div className="single_slider about_bg_12"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_13"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_14"></div>
                        </div>
                        <div className='item'>
                            <div className="single_slider about_bg_15"></div>
                        </div>
                    </OwlCarousel>
                </div>





                <div className="about_main_info">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-6">
                                <div className="single_about_info">
                                    <h3>Trekking không thể bỏ qua tại LOOP.

                                    </h3>
                                    <p>
                                        Khám phá thiên nhiên bằng những
                                        chuyến đi bộ xuyên rừng là một trong những hoạt động.
                                        Các tuyến đường trekking được thiết kế để phù hợp với mọi cấp độ kỹ năng,
                                        từ người mới bắt đầu cho đến những người đi bộ đường dài kinh nghiệm.
                                        <br />Quá trình trekking không chỉ giúp khách lưu trú khám phá vẻ đẹp của cảnh quan
                                        thiên nhiên xung quanh, mà còn có cơ hội trải nghiệm tận hưởng không gian Đà Lạt ở nhiều góc nhìn thú vị khác nhau.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="single_about_info">
                                    <h3>Thăm các nông trại Đà Lạt</h3>
                                    <p> Chúng tôi cung cấp dịch vụ hướng dẫn và kết nối khách hàng với các vườn nông trại sạch tại Đà Lạt,
                                        giúp du khách có cơ hội trực tiếp khám phá và trải nghiệm quá trình trồng trọt theo phương pháp bền vững.
                                        <br /><br />Qua các tour tham quan này, bạn không chỉ được tận hưởng không khí trong lành và
                                        cảnh quan tuyệt đẹp mà còn hiểu rõ hơn về lợi ích của nông nghiệp hữu cơ đối với sức khỏe và môi trường.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="forQuery">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-10 offset-xl-1 col-md-12">
                                <div className="Query_border">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="Query_text">
                                                <p>Liên hệ ngay

                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="phone_num">
                                                <a href="https://m.me/101023215038087" class="mobile_no">0825 -176- 929</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="instragram_area">
                    <div className="single_instagram">
                        <img src="https://imgur.com/il7RNfo.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/C26z48uruDa/?img_index=3">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/8YgXUJi.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/CtnkUfwv53R/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/exNcVbN.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/C3xJzYOoRhF/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/9bH9d6e.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/Cpt6mLDv6V6/?img_index=1">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                    <div className="single_instagram">
                        <img src="https://imgur.com/uYaRLju.png" alt="" />
                        <div className="ovrelay">
                            <a href="https://www.instagram.com/p/CxZG-5xsTlY/?img_index=1">
                                <i className="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default Service;
